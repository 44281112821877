import cx from 'classnames';
import type { ComponentPropsWithoutRef } from 'react';

import { safeAbsurd } from '~/utils/safeAbsurd';

import { Icon } from '../Icon';

import { useControl } from './ControlProvider';

type FeedbackMessageVariant = 'error' | 'info' | 'info-dark';

interface FeedbackMessageProps extends ComponentPropsWithoutRef<'p'> {
  variant: FeedbackMessageVariant;
  withIcon?: boolean;
}

export const FeedbackMessage = ({ className, variant, children, withIcon, ...rest }: FeedbackMessageProps) => {
  const control = useControl();
  return (
    <p className={cx('flex', className)} {...rest} {...getControlProps(control, variant)}>
      <span className={cx('flex flex-row justify-center', variant ? variants[variant] : null)}>
        {variant === 'info' && withIcon && <Icon name="Info" className="text-coldgray-600" />}
        <span
          className={cx('text-xs-medium', {
            'text-tertiary': variant === 'info',
            'text-secondary': variant === 'info-dark',
            'text-xs-regular': variant === 'error',
            'pl-1': withIcon,
          })}
        >
          {children}
        </span>
      </span>
    </p>
  );
};

const variants = {
  error: 'text-state-danger',
  info: 'text',
  'info-dark': '',
};

function getControlProps(control: ReturnType<typeof useControl>, variant: FeedbackMessageVariant) {
  switch (variant) {
    case 'error':
      return control.feedbackMessageProps?.error;
    case 'info':
    case 'info-dark':
      return control.feedbackMessageProps?.info;
    default:
      return safeAbsurd(variant, {}, 'unknown_feedback_message_variant');
  }
}
